import { Flex, Image, Select, Table, Tbody, Text, Th, Thead, Tooltip, Tr } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useClientContext } from "../../../../components/sidebar";
import { SelectCompanies } from "../../../../modal/SelectCompanies";
import { SelectPersonas } from "../../../../modal/SelectPersonas";
import useLoginFormStore from "../../../../store/useLoginFormStore";
import { translateJurisdiction } from "../../../../utils/utils";
import { ICertificate } from "../../../../_services/interface/certificate.interface";
import { IClientDataResponse } from "../../../../_services/interface/client.interface";
import { IClientCompanies } from "../../../../_services/interface/company.interface";
import { IPersona } from "../../../../_services/interface/persona.interface";
import { ICertificateConfigPF, ICertificateConfigPJ } from "./Certificate";

function isEmptyConfig(item: ICertificateConfigPJ | ICertificateConfigPF) {
  if ("guid_companies" in item) {
    return item.guid_companies.length === 0 && item.recurrency === "" && item.isActive === false
  }
  return item.guid_personas.length === 0 && item.recurrency === "" && item.isActive === false
}

function areConfigsEqual(
  a: ICertificateConfigPJ | ICertificateConfigPF,
  b: ICertificateConfigPJ | ICertificateConfigPF
): boolean {
  if (a.recurrency !== b.recurrency) return false
  if (a.isActive !== b.isActive) return false
  if (a.slug !== b.slug) return false
  if ("guid_companies" in a && "guid_companies" in b) {
    if (a.guid_companies.length !== b.guid_companies.length) return false
    const aSorted = [...a.guid_companies].sort()
    const bSorted = [...b.guid_companies].sort()
    return aSorted.every((guid, i) => guid === bSorted[i])
  }
  if ("guid_personas" in a && "guid_personas" in b) {
    if (a.guid_personas.length !== b.guid_personas.length) return false
    const aSorted = [...a.guid_personas].sort()
    const bSorted = [...b.guid_personas].sort()
    return aSorted.every((guid, i) => guid === bSorted[i])
  }
  return false
}

export function recurrencyIsEquals(
  arrA: Array<ICertificateConfigPJ | ICertificateConfigPF>,
  arrB: Array<ICertificateConfigPJ | ICertificateConfigPF>
): boolean {
  const filteredA = arrA.filter(itemA => {
    const existsInB = arrB.some(itemB => itemB.slug === itemA.slug)
    if (!existsInB && isEmptyConfig(itemA)) return false
    return true
  })

  const filteredB = arrB.filter(itemB => {
    const existsInA = arrA.some(itemA => itemA.slug === itemB.slug)
    if (!existsInA && isEmptyConfig(itemB)) return false
    return true
  })

  if (filteredA.length !== filteredB.length) return false

  const sortedA = [...filteredA].sort((a, b) => a.slug.localeCompare(b.slug))
  const sortedB = [...filteredB].sort((a, b) => a.slug.localeCompare(b.slug))

  return sortedA.every((item, index) => areConfigsEqual(item, sortedB[index]))
}


interface ICertificateRecurrencyProps {
  certificate: ICertificate;
  allCompanies: IClientCompanies[];
  allPersonas?: IPersona[];
  companiesRecurrency?: ICertificateConfigPJ[];
  personasRecurrency?: ICertificateConfigPF[];
  setCompaniesRecurrency?: (companiesRecurrency: ICertificateConfigPJ[], certificate: ICertificate) => void;
  setPersonasRecurrency?: (personasRecurrency: ICertificateConfigPF[], certificate: ICertificate) => void;
  showHeader: boolean;
  canEdit: boolean;
  entity: 'persona'|'company';
  customAlertMessage?: string;
}


export const CertificateRecurrency = (props: ICertificateRecurrencyProps) => {
  const [companiesRecurrency, setCompaniesRecurrency] = useState<ICertificateConfigPJ[]|undefined>(props.companiesRecurrency);
  const [personasRecurrency, setPersonasRecurrency] = useState<ICertificateConfigPF[]|undefined>(props.personasRecurrency);
  const [onlyCompanyHolding, setOnlyCompanyHolding] = useState<boolean>(false);
  const { guid_client } = useClientContext();
  const { validate } = useLoginFormStore();
  const currentClient: IClientDataResponse|undefined = validate?.clients?.find(client => client.guid_client === guid_client);

  useEffect(() => {
    if (companiesRecurrency?.length === 0 && props.entity === 'company') {
      handleAddNewRow();
    }

    if (personasRecurrency?.length === 0 && props.entity === 'persona') {
      handleAddNewRow();
    }
  }, [companiesRecurrency, personasRecurrency]); 

  useEffect(() => {
    if(props.entity === 'persona' && personasRecurrency !== undefined && props.personasRecurrency !== undefined) {
      if (!recurrencyIsEquals(personasRecurrency, props.personasRecurrency) && props.setPersonasRecurrency) {
        props.setPersonasRecurrency(personasRecurrency, props.certificate);
      }
    }

    if(props.entity === 'company' && companiesRecurrency !== undefined && props.companiesRecurrency !== undefined) {
      if (!recurrencyIsEquals(companiesRecurrency, props.companiesRecurrency) && props.setCompaniesRecurrency) {
        props.setCompaniesRecurrency(companiesRecurrency, props.certificate);
      }
    }
  }, [companiesRecurrency, personasRecurrency, props]);

  useEffect(() => {
    if (props.certificate) {
      const rules = props.certificate.rule;
      rules?.map((rule) => {
        if (rule.seekForBranches === false) {
          setOnlyCompanyHolding(true);
        }
      });
    }
  }, [props.certificate]);

  const handleUpdateGuidCompanies = (index: number, newGuidCompanies: string[]) => {
    if(companiesRecurrency === undefined) return;

    setCompaniesRecurrency((prev) => {
      if (prev === undefined) return prev;

      return prev.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            guid_companies: newGuidCompanies,
            isActive:
              item.guid_companies.length === 0 && newGuidCompanies.length > 0
                ? true
                : item.isActive,
          };
        } else {
          const updatedCompanies = item.guid_companies.filter(
            (companyGuid) => !newGuidCompanies.includes(companyGuid)
          );
  
          const shouldDeactivate = updatedCompanies.length === 0;
  
          return {
            ...item,
            guid_companies: updatedCompanies,
            isActive: shouldDeactivate ? false : item.isActive,
          };
        }
      });
    });
  };

  const handleUpdateGuidPersonas = (index: number, newGuidPersonas: string[]) => {
    if(personasRecurrency === undefined) return;

    setPersonasRecurrency((prev) => {
      if (prev === undefined) return prev;

      return prev.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            guid_personas: newGuidPersonas,
            isActive:
              item.guid_personas.length === 0 && newGuidPersonas.length > 0
                ? true
                : item.isActive,
          };
        } else {
          const updatedPersonas = item.guid_personas.filter(
            (personaGuid) => !newGuidPersonas.includes(personaGuid)
          );
  
          const shouldDeactivate = updatedPersonas.length === 0;
  
          return {
            ...item,
            guid_personas: updatedPersonas,
            isActive: shouldDeactivate ? false : item.isActive,
          };
        }
      });
    });
  };
  

  const handleUpdateIsActive = (index: number, isActive: boolean) => {
    if(props.entity === 'company') {
      setCompaniesRecurrency((prev) =>
        prev?.map((item, i) =>
          i === index ? { ...item, isActive: isActive } : item
        )
      );
    }

    if(props.entity === 'persona') {
      setPersonasRecurrency((prev) =>
        prev?.map((item, i) =>
          i === index ? { ...item, isActive: isActive } : item
        )
      );
    }
  };

  const handleUpdateRecurrency = (index: number, recurrency: string) => {
    if(props.entity === 'company') {
      setCompaniesRecurrency((prev) =>
        prev?.map((item, i) =>
          i === index ? { ...item, recurrency: recurrency } : item
        )
      );
    }

    if(props.entity === 'persona') {
      setPersonasRecurrency((prev) =>
        prev?.map((item, i) =>
          i === index ? { ...item, recurrency: recurrency } : item
        )
      );
    }
  };

  const handleAddNewRow = () => {
    if (!props.certificate.recurrency) return;
  
    // Verificar se já existe uma linha sem empresas ou sem recorrência
    if (props.entity === 'company' && companiesRecurrency?.some(c => c.guid_companies.length === 0 || c.recurrency === "")) {
      return;
    }

    if (props.entity === 'persona' && personasRecurrency?.some(c => c.guid_personas.length === 0 || c.recurrency === "")) {
      return;
    }
  
    if (props.entity === 'company') {
      // Encontrar recorrências ainda não utilizadas
      const usedRecurrencies = new Set(companiesRecurrency?.map(c => c.recurrency));
      const availableRecurrency = props.certificate.recurrency.filter((option) =>
          !(!currentClient?.useDailyRecurrence && option.value === "daily")
        )
        .find(option => !usedRecurrencies.has(option.value));
    
      // Só adiciona uma nova linha se houver recorrências disponíveis
      if (availableRecurrency) {
        setCompaniesRecurrency(prev => [
          ...(prev || []),
          {
            guid_companies: [],
            recurrency: "",
            isActive: false,
            slug: props.certificate.slug
          }
        ]);
      }
    }

    if (props.entity === 'persona') {
      // Encontrar recorrências ainda não utilizadas
      const usedRecurrencies = new Set(personasRecurrency?.map(c => c.recurrency));
      const availableRecurrency = props.certificate.recurrency.filter((option) =>
          !(!currentClient?.useDailyRecurrence && option.value === "daily")
        ).find(option => !usedRecurrencies.has(option.value));
    
      // Só adiciona uma nova linha se houver recorrências disponíveis
      if (availableRecurrency) {
        setPersonasRecurrency(prev => [
          ...(prev || []),
          {
            guid_personas: [],
            recurrency: "",
            isActive: false,
            slug: props.certificate.slug
          }
        ]);
      }
    }
  };

  const handleRemoveRow = (index: number) => {
    if(props.entity === 'company') {
      setCompaniesRecurrency((prev) => prev?.filter((_, i) => i !== index));
    }

    if(props.entity === 'persona') {
      setPersonasRecurrency((prev) => prev?.filter((_, i) => i !== index));
    }
  };

  const nameCertificate = props.certificate.slug !== props.certificate.first_slug ? `${props.certificate.name} ${translateJurisdiction(props.certificate.jurisdiction)}` : props.certificate.name;

  return (
    <>
      <Flex width="100%" flexDirection="column">
        <Table>
          {props.showHeader && (
            <Thead>
              <Tr border="none">
                <Th
                  color="#292689"
                  fontFamily="Poppins-Medium"
                  fontSize="12px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                  w="20%"
                >
                  Certidão
                </Th>

                <Th
                  color="#292689"
                  fontFamily="Poppins-Medium"
                  fontSize="12px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                  w="15%"
                >
                  {props.entity === 'company' ? "Empresas" : "Pessoas"}
                </Th>

                <Th
                  color="#292689"
                  fontFamily="Poppins-Medium"
                  fontSize="12px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                  w="15%"
                >
                  Recorrência
                </Th>

                <Th
                  color="#292689"
                  fontFamily="Poppins-Medium"
                  fontSize="12px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                  w="30%"
                >
                  Periodicidade
                </Th>

                <Th
                  color="#292689"
                  fontFamily="Poppins-Medium"
                  fontSize="12px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                  w="20%"
                >
                  <Flex
                    width="100%"
                    justifyContent="flex-end"  
                  >
                    
                  </Flex>
                </Th>
              </Tr>
            </Thead>
          )}

          <Tbody>
            {
              props.entity === 'company'
              && companiesRecurrency 
              && companiesRecurrency
                .filter((company) => company.slug === props.certificate.slug)
                .map((companyRecurrency, index) => (
                  <Tr key={index} border='none'>
                    <Th
                      fontFamily="Poppins-Medium"
                      fontStyle="normal"
                      lineHeight="normal"
                      w="20%"
                      border='none'
                    >

                      {index === 0 ? (nameCertificate) : null}
                    </Th>
              
                    <Th
                      fontFamily="Poppins-Medium"
                      fontStyle="normal"
                      lineHeight="normal"
                      w="15%"
                      border='none'
                    >
                      <SelectCompanies
                        guid_client={guid_client as string}
                        companies={ !onlyCompanyHolding ? props.allCompanies : props.allCompanies.filter(company => company.type === 'holding') }
                        showInText={true}
                        selectedCompanies={companyRecurrency.guid_companies}
                        setSelectedCompanies={(selected) => {
                          handleUpdateGuidCompanies(index, selected as string[]);
                        }}
                        disabled={!props.canEdit}
                        header="Selecionar empresas para pesquisa recorrente de consultas"
                        description=""
                        buttonText="Salvar alteração"
                        isLoading={false}
                        alertMessage={["Ao selecionar uma empresa que já tem outra recorrência configurada, ela será removida da regra anterior.", props.customAlertMessage ?? ""]}
                      />
                    </Th>
              
                    <Th
                      fontFamily="Poppins-Medium"
                      fontStyle="normal"
                      lineHeight="normal"
                      w="15%"
                      border='none'
                    >
                      <Flex
                        display="flex"
                        justifyContent="start"
                        cursor={companyRecurrency.guid_companies.length > 0 ? "pointer" : "default"}
                        title={companyRecurrency.guid_companies.length > 0 ? "Clique para ativar a recorrência" : "Selecione uma empresa para ativar a recorrência"}
                        onClick={() => {
                          if (companyRecurrency.guid_companies.length > 0 && props.canEdit) {
                            handleUpdateIsActive(index, !companyRecurrency.isActive);
                          }
                        }}
                        marginTop="1.5%"
                      >
                        {companyRecurrency.isActive ? (
                          <Image w="50px" src="../icons/switch-blue.svg" />
                        ) : (
                          <Image w="50px" src="../icons/switch-gray.svg" />
                        )}
                      </Flex>
                    </Th>
              
                    <Th
                      fontFamily="Poppins-Medium"
                      fontStyle="normal"
                      lineHeight="normal"
                      w="30%"
                      border='none'
                    >
                      <Select
                        variant="outline"
                        bg="gray.100"
                        width="100%"
                        borderRadius={6}
                        p="6px"
                        border="1px"
                        borderColor="gray.300"
                        fontSize="12px"
                        disabled={!companyRecurrency.isActive || companyRecurrency.guid_companies.length === 0 || !props.canEdit}
                        placeholder="Selecione o período de atualização"
                        onChange={(event) =>
                          handleUpdateRecurrency(index, event.target.value)
                        }
                      >
                        {props.certificate.recurrency
                          ? props.certificate.recurrency
                            .filter(
                              (option) =>
                                !companiesRecurrency.some(
                                  (company) =>
                                    company.recurrency === option.value &&
                                    company.recurrency !== companyRecurrency.recurrency
                                )
                            )
                            .filter((option) => currentClient && !currentClient.useDailyRecurrence && option.value === 'daily' ? false : true)
                            .map((option) => (
                              <option
                                key={option.value}
                                selected={option.value === companyRecurrency.recurrency}
                                value={option.value}
                              >
                                {option.name}
                              </option>
                            ))
                          : null}
                      </Select>
                    </Th>

                    <Th 
                      fontFamily="Poppins-Medium"
                      w="20%"
                      border="none"
                    >
                      <Flex
                        width="100%"
                        justifyContent="flex-end"
                      >
                        <Tooltip
                          label={
                            companiesRecurrency.length === 1
                              ? "É necessário ter pelo menos uma linha na tabela"
                              : "Remover esta linha"
                          }
                          aria-label="Remover"
                        >
                          <Image 
                            color={companiesRecurrency.length === 1 ? "gray.400" : "red.500"}
                            cursor={companiesRecurrency.length === 1 ? "not-allowed" : "pointer"}
                            opacity={companiesRecurrency.length === 1 ? 0.5 : 1}
                            onClick={() => {
                              if (companiesRecurrency.length > 1) {
                                handleRemoveRow(index);
                              }
                            }}
                            w="30px"
                            src="../icons/x.svg"
                          />
                        </Tooltip>
                      </Flex>
                    </Th>
                  </Tr>
              ))
            }

            {
              props.entity === 'persona'
              && props.allPersonas
              && personasRecurrency 
              && personasRecurrency
                .filter((persona) => persona.slug === props.certificate.slug)
                .map((personaRecurrency, index) => (
                  <Tr key={index} border='none'>
                    <Th
                      fontFamily="Poppins-Medium"
                      fontStyle="normal"
                      lineHeight="normal"
                      w="20%"
                      border='none'
                    >

                      {index === 0 ? (nameCertificate) : null}
                    </Th>
              
                    <Th
                      fontFamily="Poppins-Medium"
                      fontStyle="normal"
                      lineHeight="normal"
                      w="15%"
                      border='none'
                    >
                      {
                        props.allPersonas && (
                          <SelectPersonas
                            guid_client={guid_client as string}
                            personas={props.allPersonas}
                            selectedPersonas={personaRecurrency.guid_personas}
                            setSelectedPersonas={(selected) => {
                              handleUpdateGuidPersonas(index, selected as string[]);
                            }}
                            header="Selecione as pessoas para ativar a recorrência de certidões e consultas"
                            description="Selecione as pessoas que você deseja ativar a recorrência de certidões e consultas"
                            buttonText="Ativar recorrência"
                            isLoading={false}
                            alertMessage="Ao selecionar uma pessoa que ja esta em outra linha, ela será removida da linha anterior."
                          />
                        )
                      }
                    </Th>
              
                    <Th
                      fontFamily="Poppins-Medium"
                      fontStyle="normal"
                      lineHeight="normal"
                      w="15%"
                      border='none'
                    >
                      <Flex
                        display="flex"
                        justifyContent="start"
                        cursor={personaRecurrency.guid_personas.length > 0 ? "pointer" : "default"}
                        title={personaRecurrency.guid_personas.length > 0 ? "Clique para ativar a recorrência" : "Selecione uma pessoa para ativar a recorrência"}
                        onClick={() => {
                          if (personaRecurrency.guid_personas.length > 0 && props.canEdit) {
                            handleUpdateIsActive(index, !personaRecurrency.isActive);
                          }
                        }}
                        marginTop="1.5%"
                      >
                        {personaRecurrency.isActive ? (
                          <Image w="50px" src="../icons/switch-blue.svg" />
                        ) : (
                          <Image w="50px" src="../icons/switch-gray.svg" />
                        )}
                      </Flex>
                    </Th>
              
                    <Th
                      fontFamily="Poppins-Medium"
                      fontStyle="normal"
                      lineHeight="normal"
                      w="30%"
                      border='none'
                    >
                      <Select
                        variant="outline"
                        bg="gray.100"
                        width="100%"
                        borderRadius={6}
                        p="6px"
                        border="1px"
                        borderColor="gray.300"
                        fontSize="12px"
                        disabled={!personaRecurrency.isActive || personaRecurrency.guid_personas.length === 0 || !props.canEdit}
                        placeholder="Selecione o período de atualização"
                        onChange={(event) =>
                          handleUpdateRecurrency(index, event.target.value)
                        }
                      >
                        {props.certificate.recurrency
                          ? props.certificate.recurrency
                            .filter(
                              (option) =>
                                !personasRecurrency.some(
                                  (persona) =>
                                    persona.recurrency === option.value &&
                                    persona.recurrency !== personaRecurrency.recurrency
                                )
                            )
                            .filter((option) => currentClient && !currentClient.useDailyRecurrence && option.value === 'daily' ? false : true)
                            .map((option) => (
                              <option
                                key={option.value}
                                selected={option.value === personaRecurrency.recurrency}
                                value={option.value}
                              >
                                {option.name}
                              </option>
                            ))
                          : null}
                      </Select>
                    </Th>

                    <Th 
                      fontFamily="Poppins-Medium"
                      w="20%"
                      border="none"
                    >
                      <Flex
                        width="100%"
                        justifyContent="flex-end"
                      >
                        <Tooltip
                          label={
                            personasRecurrency.length === 1
                              ? "É necessário ter pelo menos uma linha na tabela"
                              : "Remover esta linha"
                          }
                          aria-label="Remover"
                        >
                          <Image 
                            color={personasRecurrency.length === 1 ? "gray.400" : "red.500"}
                            cursor={personasRecurrency.length === 1 ? "not-allowed" : "pointer"}
                            opacity={personasRecurrency.length === 1 ? 0.5 : 1}
                            onClick={() => {
                              if (personasRecurrency.length > 1) {
                                handleRemoveRow(index);
                              }
                            }}
                            w="30px"
                            src="../icons/x.svg"
                          />
                        </Tooltip>
                      </Flex>
                    </Th>
                  </Tr>
              ))
            }
          </Tbody>
        </Table>

        <Flex 
          width="100%"
          justifyContent="center"
          marginTop="15px"
          marginBottom="30px"
        >
          {
            props.entity === 'company' && (
              <Tooltip
                label={
                  companiesRecurrency?.some(c => c.guid_companies.length === 0 || c.recurrency === "")
                    ? "Você precisa selecionar empresas e definir a recorrência na linha existente antes de adicionar uma nova."
                    : props.certificate.recurrency?.filter((option) =>
                        !(!currentClient?.useDailyRecurrence && option.value === "daily")
                      )
                      .every(option => 
                        companiesRecurrency?.some(company => company.recurrency === option.value)
                      )
                      ? "Todas as recorrências já foram utilizadas. Não é possível adicionar novas linhas."
                      : "Adicionar nova linha"
                }
              >
                <Text
                  fontFamily="Poppins-Medium"
                  fontStyle="normal"
                  lineHeight="normal"
                  fontSize="12px"
                  cursor={
                    props.certificate.recurrency?.filter((option) =>
                      !(!currentClient?.useDailyRecurrence && option.value === "daily")
                    )
                    .some(option => 
                      !companiesRecurrency?.some(company => company.recurrency === option.value)
                    ) && !companiesRecurrency?.some(c => c.guid_companies.length === 0 || c.recurrency === "")
                      ? "pointer"
                      : "not-allowed"
                  }
                  color={
                    props.certificate.recurrency?.filter((option) =>
                      !(!currentClient?.useDailyRecurrence && option.value === "daily")
                    )
                    .some(option => 
                      !companiesRecurrency?.some(company => company.recurrency === option.value)
                    ) && !companiesRecurrency?.some(c => c.guid_companies.length === 0 || c.recurrency === "")
                      ? "blue.500"
                      : "gray.400"
                  }
                  onClick={handleAddNewRow}
                >
                  + adicionar nova linha
                </Text>
              </Tooltip>
            )
          }

          {
            props.entity === 'persona' && (
              <Tooltip
                label={
                  personasRecurrency?.some(c => c.guid_personas.length === 0 || c.recurrency === "")
                    ? "Você precisa selecionar pessoas e definir a recorrência na linha existente antes de adicionar uma nova."
                    : props.certificate.recurrency?.filter((option) =>
                        !(!currentClient?.useDailyRecurrence && option.value === "daily")
                      )
                      .every(option => 
                        personasRecurrency?.some(company => company.recurrency === option.value)
                      )
                      ? "Todas as recorrências já foram utilizadas. Não é possível adicionar novas linhas."
                      : "Adicionar nova linha"
                }
              >
                <Text
                  fontFamily="Poppins-Medium"
                  fontStyle="normal"
                  lineHeight="normal"
                  fontSize="12px"
                  cursor={
                    props.certificate.recurrency?.filter((option) =>
                      !(!currentClient?.useDailyRecurrence && option.value === "daily")
                    )
                    .some(option => 
                      !personasRecurrency?.some(company => company.recurrency === option.value)
                    ) && !personasRecurrency?.some(c => c.guid_personas.length === 0 || c.recurrency === "")
                      ? "pointer"
                      : "not-allowed"
                  }
                  color={
                    props.certificate.recurrency?.filter((option) =>
                      !(!currentClient?.useDailyRecurrence && option.value === "daily")
                    ).some(option => 
                      !personasRecurrency?.some(company => company.recurrency === option.value)
                    ) && !personasRecurrency?.some(c => c.guid_personas.length === 0 || c.recurrency === "")
                      ? "blue.500"
                      : "gray.400"
                  }
                  onClick={handleAddNewRow}
                >
                  + adicionar nova linha
                </Text>
              </Tooltip>
            )
          }
          
        </Flex>
      </Flex>
    </>
  );
}
