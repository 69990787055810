import { Flex, Text, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BlockSwitch } from "../../../../components/BlockSwitch";
import { FormButton } from "../../../../components/login/button.component";
import { translateJurisdiction } from "../../../../utils/utils";
import { getClientCompanies } from "../../../../_services/company.service";
import { configCertificate, getConfigCertificate } from "../../../../_services/config.service";
import { ICertificate } from "../../../../_services/interface/certificate.interface";
import { IClientCompanies } from "../../../../_services/interface/company.interface";
import { IPersona } from "../../../../_services/interface/persona.interface";
import { listWithoutPermissionPersonas } from "../../../../_services/persona.service";
import { CertificateRecurrency, recurrencyIsEquals } from "./CertificateRecurrency";

interface ICertificateProps {
  refreshData: boolean;
  guid_client: string;
  certificates: ICertificate[];
  hasChangeHook: React.Dispatch<React.SetStateAction<boolean>>;
  submit: boolean;
  setSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  submitError: boolean;
  setSubmitError: React.Dispatch<React.SetStateAction<boolean>>;

  showPersonas: boolean;

  canEdit: boolean;
  canDelete: boolean;
}

export interface ICertificateConfigPJ {
  guid_companies: string[];
  recurrency: string;
  isActive: boolean;
  slug: string;
}

export interface ICertificateConfigPF {
  guid_personas: string[];
  recurrency: string;
  isActive: boolean;
  slug: string;
}

export const Certificate = (props: ICertificateProps) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  
  const [allCompanies, setAllCompanies] = useState<IClientCompanies[]>([]);

  const [allPersonas, setAllPersonas] = useState<IPersona[]>([]);
  
  const [certificatesActivePJ, setCertificatesActivePJ] = useState<string[]>([]);
  const [certificatesActivePF, setCertificatesActivePF] = useState<string[]>([]);

  const [certificatesPJConfig, setCertificatesPJConfig] = useState<ICertificateConfigPJ[]>([]);
  const [certificatesPFConfig, setCertificatesPFConfig] = useState<ICertificateConfigPF[]>([]);

  const [initialCertificatesActivePJ, setInitialCertificatesActivePJ] = useState<string[]>([]);
  const [initialCertificatesActivePF, setInitialCertificatesActivePF] = useState<string[]>([]);

  const [initialCertificatesPJConfig, setInitialCertificatesPJConfig] = useState<ICertificateConfigPJ[]>([]);
  const [initialCertificatesPFConfig, setInitialCertificatesPFConfig] = useState<ICertificateConfigPF[]>([]);

  const submit = async () => {
    setSubmitDisabled(true);

    const hasLineWithoutRecurrencyOrCompany = certificatesPJConfig.some(config => {
      if (config.recurrency && config.guid_companies.length === 0) {
        return true;
      }

      if (!config.recurrency && config.guid_companies.length > 0) {
        return true;
      }
    });

    if(hasLineWithoutRecurrencyOrCompany) {
      toast({
        title: 'Erro ao atualizar',
        description: 'Existem linhas sem recorrência ou empresa selecionada',
        status: 'error',
        duration: 4000,
        isClosable: true
      });
      props.setSubmitError(true);
      setSubmitDisabled(false);
      return;
    }

    const hasLineWithoutRecurrencyOrPersona = certificatesPFConfig.some(config => {
      if (config.recurrency && config.guid_personas.length === 0) {
        return true;
      }

      if (!config.recurrency && config.guid_personas.length > 0) {
        return true;
      }
    });

    if(hasLineWithoutRecurrencyOrPersona && props.showPersonas) {
      toast({
        title: 'Erro ao atualizar',
        description: 'Existem linhas sem recorrência ou pessoa selecionada',
        status: 'error',
        duration: 4000,
        isClosable: true
      });
      props.setSubmitError(true);
      setSubmitDisabled(false);
      return;
    }

    const {status, response} = await configCertificate(props.guid_client,{
      productsCompany: certificatesPJConfig,
      productsPersona: certificatesPFConfig,
      slugsActivePJ: certificatesActivePJ,
      slugsActivePF: certificatesActivePF
    });

    if(status === 200) {
      toast({
        title: 'Configuração Atualizada',
        description: 'Os dados foram atualizados com sucesso',
        status: 'success',
        duration: 2000,
        isClosable: true
      });

      props.setSubmit(false);
      props.hasChangeHook(false);
    }

    if(status === 400 && response && 'message' in response) {
      toast({
        title: 'Erro ao atualizar',
        description: response.message,
        status: 'error',
        duration: 4000,
        isClosable: true
      });
      props.setSubmitError(true);
    } else if (status === 400) {
      toast({
        title: 'Erro ao atualizar',
        description: 'Ocorreu um erro ao atualizar as informações',
        status: 'error',
        duration: 4000,
        isClosable: true
      });
      props.setSubmitError(true);
    }

    setSubmitDisabled(false);
  }

  const getAllCompanies = async () => {
    const { status, response } = await getClientCompanies(props.guid_client);
    if (status === 200) {
      if (Array.isArray(response)) {
        setAllCompanies(response);
      } else {
        toast({
          title: 'Ocorreu um erro',
          description: 'Ocorreu um erro ao tentar puxar as informações das empresas',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      }
    }
  }

  const getAllPersonas = async () => {
    if (props.guid_client) {
      const filters = {
        page: 1,
        row_by_page: '-1'
      };

      const { status, response } = await listWithoutPermissionPersonas(props.guid_client, filters);

      if (
        status === 200
        && 'meta' in response 
        && 'data' in response
        && response.data !== undefined
      ) {
        const data: IPersona[] = response.data;
        setAllPersonas(data);
      } else {
        toast({
          title: 'Ocorreu um erro',
          description: 'Ocorreu um erro ao tentar puxar as informações das pessoas físicas',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      }
    }
  }

  const configFields = async () => {
    setIsLoading(true);
    const {status, response} = await getConfigCertificate(props.guid_client);

    if(
      status === 200 
      && response
      && 'productsCompany' in response
      && 'productsPersona' in response
      && 'slugsActivePJ' in response
      && 'slugsActivePF' in response
    ) {
      setCertificatesPJConfig(response.productsCompany);
      setCertificatesPFConfig(response.productsPersona);
      setCertificatesActivePJ(response.slugsActivePJ);
      setCertificatesActivePF(response.slugsActivePF);

      setInitialCertificatesPJConfig(response.productsCompany);
      setInitialCertificatesPFConfig(response.productsPersona);
      setInitialCertificatesActivePJ(response.slugsActivePJ);
      setInitialCertificatesActivePF(response.slugsActivePF);
    }

    setTimeout(() => setIsLoading(false), 500);
  }

  useEffect(() => {
    configFields();
    getAllCompanies();
    getAllPersonas();
  }, [])

  useEffect(() => {
    if(props.submit) {
      submit();
    }
  }, [props.submit]);

  useEffect(() => {
    if(props.refreshData) {
      configFields();
    }
  }, [props.refreshData]);

  useEffect(() => {
    props.hasChangeHook(false);

    if (certificatesActivePJ.sort().join() !== initialCertificatesActivePJ.sort().join()) {
      props.hasChangeHook(true);
    }
    
    if (certificatesActivePF.sort().join() !== initialCertificatesActivePF.sort().join()) {
      props.hasChangeHook(true);
    }
    
    if (!recurrencyIsEquals(certificatesPJConfig, initialCertificatesPJConfig)) {
      props.hasChangeHook(true);
    }
    
    if (!recurrencyIsEquals(certificatesPFConfig, initialCertificatesPFConfig)) {
      props.hasChangeHook(true);
    }
  }, [
    certificatesActivePJ,
    certificatesActivePF,
    certificatesPJConfig,
    certificatesPFConfig
  ]);

  const changeStatusCertificatePJ = (value: boolean, slug: string) => {
    if(value) {
      setCertificatesActivePJ([...certificatesActivePJ, slug]);
    } else {
      setCertificatesActivePJ(certificatesActivePJ.filter(value => value !== slug));
    }
  }

  const changeStatusCertificatePF = (value: boolean, slug: string) => {
    if(value) {
      setCertificatesActivePF([...certificatesActivePF, slug]);
    } else {
      setCertificatesActivePF(certificatesActivePF.filter(value => value !== slug));
    }
  }

  const handleSetCompaniesRecurrency = (
    newCompaniesRecurrency: ICertificateConfigPJ[],
    certificate: ICertificate
  ) => {
    const { slug } = certificate;

    const certificatesPJConfigByCertificate = certificatesPJConfig.filter(
      (config) => config.slug === slug
    );

    const isEquals = recurrencyIsEquals(newCompaniesRecurrency, certificatesPJConfigByCertificate);

    if(isEquals) {
      return false;
    }

    const certificatesPJ = [
      ...certificatesPJConfig.filter((config) => config.slug !== slug),
      ...newCompaniesRecurrency,
    ]
  
    setCertificatesPJConfig(certificatesPJ);
  };

  const handleSetPersonasRecurrency = (
    newPersonasRecurrency: ICertificateConfigPF[],
    certificate: ICertificate
  ) => {
    const { slug } = certificate;

    const certificatesPFConfigByCertificate = certificatesPFConfig.filter(
      (config) => config.slug === slug
    );

    const isEquals = recurrencyIsEquals(newPersonasRecurrency, certificatesPFConfigByCertificate);

    if(isEquals) {
      return false;
    }

    const certificatesPF = [
      ...certificatesPFConfig.filter((config) => config.slug !== slug),
      ...newPersonasRecurrency,
    ]
  
    setCertificatesPFConfig(certificatesPF);
  };

  let headerDisplayed = false;

  return (
    <Flex direction="column" w="100%">
      <Flex marginBottom="32px">
        <Text fontSize="18px" fontFamily="Poppins-Medium" fontWeight="500">Gerenciador de certidões</Text>
      </Flex>

      <Flex margin="32px 0">
        <Text fontSize="18px" fontFamily="Poppins-Medium" fontWeight="500">Para empresas</Text>
      </Flex>

      <Flex width="100%" flexDirection="row" gap="24px" flexWrap="wrap" justifyContent="flex-start">
        {
          isLoading ? (
            Array.from({ length: 10 }, (_, index) => (
              <BlockSwitch
                key={index}
                isActive={false}
                setIsActive={(value: boolean) => null}
                name="carregando"
                icon="/icons/gerenciado_cnds.png"
                isLoading={true}
              />
            ))
          ) : (
            props.certificates.flatMap((certificate, index) => {
              if (certificate.children?.some(child => !child.databroker_name || child.databroker_name === '')) {
                return certificate.children.map((child, i) => (
                  <BlockSwitch
                    key={child.slug || i}
                    isActive={certificatesActivePJ.includes(child.slug)}
                    setIsActive={(value: boolean) => changeStatusCertificatePJ(value, child.slug)}
                    name={`${child.name} ${translateJurisdiction(child.jurisdiction)}`}
                    icon="/icons/gerenciado_cnds.png"
                    minWidth="260px"
                    disabled={!props.canDelete}
                  />
                ));
              } else {
                return (
                  <BlockSwitch
                    key={certificate.slug || index}
                    isActive={certificatesActivePJ.includes(certificate.slug)}
                    setIsActive={(value: boolean) => changeStatusCertificatePJ(value, certificate.slug)}
                    name={certificate.name}
                    icon="/icons/gerenciado_cnds.png"
                    minWidth="260px"
                    disabled={!props.canDelete}
                  />
                );
              }
            })
          )
        }
      </Flex>

      <Flex mt="35px" flexDirection="column" w="100%">
        {
          props.certificates
            .filter((certificate) => certificate.config_company === 'ALL' || certificate.config_company === 'AUTOMATIC')
            .flatMap((certificate, index) => {

              if (certificate.children?.some(child => !child.databroker_name || child.databroker_name === '')) {
                return (
                    certificate.children
                    .sort((a, b) => (a.slug === 'cnd_federal' ? -1 : b.slug === 'cnd_federal' ? 1 : 0))
                    .filter(child => certificatesActivePJ.includes(child.slug))
                    .map((child, i) => {
                      const showHeader = !headerDisplayed;
                      headerDisplayed = true;

                      return (<>
                        <React.Fragment key={`${child.slug}-${i}-${certificatesActivePJ.length}`}>
                          <CertificateRecurrency
                            certificate={child}
                            allCompanies={allCompanies}
                            allPersonas={allPersonas}
                            companiesRecurrency={certificatesPJConfig.filter(config => config.slug === child.slug)}
                            setCompaniesRecurrency={handleSetCompaniesRecurrency}
                            showHeader={showHeader}
                            canEdit={props.canEdit}
                            entity="company"
                          />
                        </React.Fragment>

                        <hr />
                      </>)
                    })

                  )
              } else {
                if(!certificatesActivePJ.includes(certificate.slug)) {
                  return (<></>);
                }

                const showHeader = !headerDisplayed;
                headerDisplayed = true;

                return (<>
                  <React.Fragment key={`${certificate.slug}-${index}-${certificatesActivePJ.length}`}>
                    <CertificateRecurrency
                      certificate={certificate}
                      allCompanies={allCompanies}
                      allPersonas={allPersonas}
                      companiesRecurrency={certificatesPJConfig.filter(config => config.slug === certificate.slug)}
                      setCompaniesRecurrency={handleSetCompaniesRecurrency}
                      showHeader={showHeader}
                      canEdit={props.canEdit}
                      entity="company"
                    />
                  </React.Fragment>
                  <hr />
                </>)
              }
          })
        }
      </Flex>

      {
        props.showPersonas && (
          <>
            <Flex margin="32px 0">
              <Text fontSize="18px" fontFamily="Poppins-Medium" fontWeight="500">Para pessoa física</Text>
            </Flex>

            <Flex width="100%" flexDirection="row" gap="24px" flexWrap="wrap" justifyContent="flex-start">
              {
                isLoading ? (
                  Array.from({ length: 10 }, (_, index) => (
                    <BlockSwitch
                      key={index}
                      isActive={false}
                      setIsActive={(value: boolean) => null}
                      name="carregando"
                      icon="/icons/gerenciado_cnds.png"
                      isLoading={true}
                    />
                  ))
                ) : (
                  props.certificates
                  .filter((certificate) => certificate.config_persona === 'ALL' || certificate.config_persona === 'AUTOMATIC')
                  .flatMap((certificate, index) => {
                    if (certificate.children?.some(child => !child.databroker_name || child.databroker_name === '')) {
                      return certificate.children.map((child, i) => (
                        <BlockSwitch
                          key={child.slug || i}
                          isActive={certificatesActivePF.includes(child.slug)}
                          setIsActive={(value: boolean) => changeStatusCertificatePF(value, child.slug)}
                          name={`${child.name} ${translateJurisdiction(child.jurisdiction)}`}
                          icon="/icons/gerenciado_cnds.png"
                          minWidth="260px"
                          disabled={!props.canDelete}
                        />
                      ));
                    } else {
                      return (
                        <BlockSwitch
                          key={certificate.slug || index}
                          isActive={certificatesActivePF.includes(certificate.slug)}
                          setIsActive={(value: boolean) => changeStatusCertificatePF(value, certificate.slug)}
                          name={certificate.name}
                          icon="/icons/gerenciado_cnds.png"
                          minWidth="260px"
                          disabled={!props.canDelete}
                        />
                      );
                    }
                  })
                )
              }
            </Flex>

            <Flex mt="35px" flexDirection="column" w="100%">
              {
                props.certificates
                  .filter((certificate) => certificate.config_persona === 'ALL' || certificate.config_persona === 'AUTOMATIC')
                  .flatMap((certificate, index) => {

                    if (certificate.children?.some(child => !child.databroker_name || child.databroker_name === '')) {
                      return (
                          certificate.children
                          .sort((a, b) => (a.slug === 'cnd_federal' ? -1 : b.slug === 'cnd_federal' ? 1 : 0))
                          .filter(child => certificatesActivePF.includes(child.slug))
                          .map((child, i) => {
                            return (<>
                              <React.Fragment key={`${child.slug}-${i}-${certificatesActivePJ.length}`}>
                                <CertificateRecurrency
                                  certificate={child}
                                  allCompanies={allCompanies}
                                  allPersonas={allPersonas}
                                  personasRecurrency={certificatesPFConfig.filter(config => config.slug === child.slug)}
                                  setPersonasRecurrency={handleSetPersonasRecurrency}
                                  showHeader={index === 0 && i === 0}
                                  canEdit={props.canEdit}
                                  entity="persona"
                                />
                              </React.Fragment>

                              <hr />
                            </>)
                          })
                        )
                    } else {
                      if(!certificatesActivePF.includes(certificate.slug)) {
                        return (<></>);
                      }

                      return (<>
                        <React.Fragment key={`${certificate.slug}-${index}-${certificatesActivePJ.length}`}>
                          <CertificateRecurrency
                            certificate={certificate}
                            allCompanies={allCompanies}
                            allPersonas={allPersonas}
                            personasRecurrency={certificatesPFConfig.filter(config => config.slug === certificate.slug)}
                            setPersonasRecurrency={handleSetPersonasRecurrency}
                            showHeader={index === 0}
                            canEdit={props.canEdit}
                            entity="persona"
                          />
                        </React.Fragment>
                        <hr />
                      </>)
                    }
                })
              }
            </Flex>
          </>
        )
      }

      <hr />

      <Flex width="100%" justifyContent="flex-start" marginTop="35px">
        <FormButton onClick={submit} disabled={submitDisabled || isLoading}>
          Salvar alterações
        </FormButton>
      </Flex>
    </Flex>
  );
}
